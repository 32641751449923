<template>
    <validation-observer tag="div" ref="observer" v-if="!loading">
        <b-card :header="$t('settings.dhl.group.common')">
            <b-card-text>
                <b-row>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.dhl_active"
                                         name="dhl_active"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.dhl_sandbox_active"
                                         name="dhl_sandbox_active"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-input-group v-model="form.dhl_city"
                                        name="city"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="form.dhl_countryCode"
                                         :options="getCountries()"
                                         name="country"
                                         :label-prefix="labelPrefix"
                                         text-field="name"
                                         value-field="iso3"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.dhl_zipCode"
                                        name="zip"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-select-group v-model="form.dhl_production_label_format"
                                         name="label_format"
                                         :label-prefix="labelPrefix"
                                         :options="getDhlLabelFormats()"
                        ></zw-select-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
        <b-card :header="$t('settings.dhl.group.weight')">
            <b-card-text>
                <b-row>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.dhl_production_dhl_weight_calulcate"
                                         name="calculate_weight"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.dhl_production_weight"
                                        name="default_package_weight"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                        <zw-switch-group v-model="form.dhl_production_warenpost_weight_calulcate"
                                         name="calculate_warenpost_weight"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                    <b-col cols="4">
                        <zw-input-group v-model="form.dhl_production_weight"
                                        name="default_package_weight"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>

        <b-card-group deck>
            <b-card
                :header="$t('settings.dhl.group.sandbox')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_sandbox_api_user"
                                            name="application_id"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_sandbox_api_password"
                                            name="application_token"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_sandbox_receiver_id"
                                            name="receiver_id"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

            <b-card
                :header="$t('settings.dhl.group.production')"
                header-tag="header"
            >
                <b-card-text>
                    <b-row>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_api_user"
                                            name="application_id"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_api_password"
                                            name="application_token"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_user"
                                            name="user"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_password"
                                            name="signature"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_receiver_id"
                                            name="receiver_id"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_account_number"
                                            name="account_number"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_account_number_int"
                                            name="account_number_int"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_account_number_warenpost"
                                            name="account_number_warenpost"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                        <b-col cols="12">
                            <zw-input-group v-model="form.dhl_production_account_number_warenpost_int"
                                            name="account_number_warenpost_int"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-card-group>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsDhl',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            form: {},
            labelPrefix: 'settings.dhl.label.'
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getCountries', 'getDhlLabelFormats']),
        ...mapGetters('Settings', ['getDhl']),
        shown() {
            this.loading = true
            const settingsDhl = this.$store.dispatch('Settings/fetchDhl')

            Promise.all([settingsDhl]).then(() => {
                this.form = this.getDhl()

                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )

                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>